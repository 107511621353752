import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import { SectionTab } from "polotno/side-panel";
import MdPhotoLibrary from "@meronex/icons/md/MdPhotoLibrary";
import axios from "api/axios";
import Endpoints from "api/Endpoints";

import Swal from "sweetalert2";
import { ImagesGrid } from "polotno/side-panel/images-grid";

import { useDispatch, useSelector } from "react-redux";
// dummy store slice for img grid
import { addId, setPage } from "store/slices/templateSlice";
import {
  addTemplates,
} from "store/slices/templateSlice";
export const TemplatesPanel = observer(({ store }) => {
  const templates = useSelector((state) => state.templates.templates);
  const page = useSelector((state) => state.templates.page);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const templateParamId = urlParams.get("templateId");
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState('');
const localStorageReset = ()=>{
  localStorage.setItem("cancelState", true);
  localStorage.setItem("apiCounter", 0);
  localStorage.setItem("savePressed", false);
  localStorage.setItem("updatePressed", false);
  localStorage.setItem("temId", undefined);
  localStorage.setItem("templateStatus", undefined);
  localStorage.setItem("tempJson", undefined);
}

  const getTemplate = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`${Endpoints.template}?page=${page}`);
      const fetchedData = res?.data?.result?.templates?.data;
      // console.log(fetchedData, "FETCHED DATA");
      for (let i = 0; i < fetchedData.length; i++) {
        dispatch(
          addTemplates({
            json: fetchedData[i]?.settings,
            prev: fetchedData[i]?.medias[0]?.media_url,
            id: fetchedData[i]?.id,
          })
        );
      }
      const totalPages = res?.data?.result?.templates?.last_page 
      setTotalPages(totalPages)
    } catch (error) {
      // console.log("error in API", error.message);
      Swal.fire(
        "Error!",
        "Something went wrong! Please try closing the editor and come back again",
        "error"
      ).then((result) => {
        if (result.isConfirmed) {
          localStorageReset()
        } else if (result.isDenied) {
          localStorageReset()
        }
      });
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    getTemplate();
  }, []);
  return (
    <>
      <div style={{ height: "100%" }}>
        <ImagesGrid
          shadowEnabled={false}
          images={templates?.map((data) => data.prev).flat()}
          getPreview={(item) => {
            const obj = templates?.find((it) => it.prev === item);
            store.waitLoading();
            return obj?.prev;
          }}
          crossOrigin={null}
          isLoading={loading}
          onSelect={async (item) => {
            const obj = templates?.find((it) => it.prev === item);
            // console.log("on select", obj.json);
            store.waitLoading();
            await store.loadJSON(await JSON.parse(obj?.json));
            // SetTemplateId(obj?.id);
            if (templateParamId) {
              const idtemplate = JSON.parse(templateParamId);
              dispatch(addId(idtemplate));
            } else {
              dispatch(addId(obj?.id));
            }
          }}
          rowsNumber={1}
          loadMore={async() => {
            if(page <= totalPages){
              dispatch(setPage(page + 1))
              await getTemplate(); 
            }else{
            return false
            }
          }}
        />
      </div>
    </>
  );
});

// define the new custom section
export const CustomTemplateTab = {
  name: "custom-templates",
  Tab: (props) => (
    <SectionTab name="Templates" {...props}>
      <MdPhotoLibrary />
    </SectionTab>
  ),
  // we need observer to update component automatically on any store changes
  Panel: TemplatesPanel,
};
