import React from "react";
import { Button, Menu, MenuDivider, MenuItem, Card } from "@blueprintjs/core";
import { Tooltip2, Popover2 } from "@blueprintjs/popover2";
// import { useState } from "react";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import axios from "api/axios";
import Endpoints from "api/Endpoints";
import {
  showPopUpHandler,
  popUpImgHandler,
  setIsBad,
} from "../../../store/slices/uiSlice";
import {
  // addId,
  removeTemplates,
  // updateTemplates,
} from "store/slices/templateSlice";
import CustomTooltip from "components/custom-tooltip/CustomTooltip";
import { urltoFile } from "utils/urltoFile";

const Savebutton = ({ store }) => {
  const queryString = window.location.search;

  const urlParams = new URLSearchParams(queryString);
  const deviceType = urlParams.get("deviceType");
  const templateParamId = urlParams.get("templateId");

  const dispatch = useDispatch();
  const showPopUp = useSelector((state) => state.ui.showPopUp);
  const isBad = useSelector((state) => state.ui.isBad);
  const templatesId = useSelector((state) => state.templates.templateId);

  const stringCheck = async () => {
    let isBadFlag = false;

    await store.activePage?.children.forEach((element) => {
      const type = element?.type;
      const text = element?.text;
      const substr = "*";

      if (type === "text") {
        if (text.includes(substr)) {
          isBadFlag = true;
        }
      }
    });

    if (isBadFlag) {
      Swal.fire("You cannot save until you remove the bad word");
    } else {
      saveHandler();
    }
  };

  const saveHandler = async () => {
    if (!showPopUp && store.pages.length > 0) {
      const imgUrl = await store.toDataURL();

      dispatch(popUpImgHandler(imgUrl));
      dispatch(showPopUpHandler());
    } else {
      Swal.fire("Error!", "No Page Added ", "error");
    }
  };

  const updateSingleImgApi = async (payload, deviceType) => {
    // for (let it of payload) {
    //   console.log(it, "update media api");
    // }
    const headers = {
      Accept: "application/json",
    };
    try {
      const res = await axios.post(`${Endpoints.updateMedia}`, payload, {
        headers: headers,
      });
      console.log(res, "Res for single media update");
    } catch (error) {
      Swal.fire(
        "Error!",
        "Something went wrong! Please try closing the editor and come back again",
        "error"
      );
    }
  };

  const singleSave = async () => {
    const payload = new FormData();
    const data = new Date();
    const url = await store.toDataURL({ mimeType: "image/jpg" });
    const file = await urltoFile(url, data.getTime() + ".jpg", "image/jpeg");
    payload.append("media[]", file);
    payload.append("user_id", "1");
    payload.append("other", "Ads");
    payload.append("device_type", deviceType);
    payload.append("template_id", templateParamId);

    await updateSingleImgApi(payload, deviceType);
    console.log("SINGLE SAVE BUTTON IS CLICKED");
  };

  const deleteAPI = async () => {
    try {
      // Send a DELETE request using Axios
      const res = await axios.delete(`${Endpoints.template}/${templatesId}`);
      // Handle success or perform any additional actions
      // console.log("Delete request", res);
      return res.status;
    } catch (error) {
      // Handle errors
      Swal.fire(
        "Error!",
        "Something went wrong! Please try closing the editor and come back again",
        "error"
      );
      // console.error("Error while making the DELETE request:", error.message);
    }
  };

  const handleDeleteClick = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const status = await deleteAPI();
        if (status === 200) {
          // console.log(status, "delete status");
          Swal.fire("Deleted!", "Your Ad has been deleted.", "success");
          dispatch(removeTemplates(templatesId));
          store.clear();
        } else {
          Swal.fire("Failed", "Your Ad is Not Deleted.", "error");
        }
      }
    });
  };

  return (
    <>
      <div className="toolbar_actions_container bp4-overflow-list">
        <Tooltip2
          content="Download"
          position="bottom"
          openOnTargetFocus={false}
        >
          <Button
            icon="download"
            className="toolbar_buttons"
            onClick={() => {
              store.saveAsImage();
            }}
          ></Button>
        </Tooltip2>
        {/* <Tooltip2 content="Delete Custom Template" position="bottom">
          <Button
            icon="delete"
            className="toolbar_buttons"
            disabled={templatesId ? false : true}
            onClick={() => {
              handleDeleteClick();
            }}
          ></Button>
        </Tooltip2> */}
        <Tooltip2 content="Clear Page" position="bottom">
          <Button
            icon="clean"
            className="toolbar_buttons"
            onClick={() => {
              store.clear({
                keepHistory: true,
              });
              store.addPage();
            }}
          ></Button>
        </Tooltip2>
        <Tooltip2 content="Save" position="bottom">
          <Button
            onClick={stringCheck}
            // icon="floppy-disk"
            className="toolbar_buttons"
            style={{ fill: "green" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-floppy-fill floppy-svg"
              viewBox="0 0 16 16"
            >
              <path d="M0 1.5A1.5 1.5 0 0 1 1.5 0H3v5.5A1.5 1.5 0 0 0 4.5 7h7A1.5 1.5 0 0 0 13 5.5V0h.086a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5H14v-5.5A1.5 1.5 0 0 0 12.5 9h-9A1.5 1.5 0 0 0 2 10.5V16h-.5A1.5 1.5 0 0 1 0 14.5v-13Z" />
              <path d="M3 16h10v-5.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5V16Zm9-16H4v5.5a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5V0ZM9 1h2v4H9V1Z" />
            </svg>
          </Button>
        </Tooltip2>
      </div>
    </>
  );
};

export default Savebutton;
