import "./App.css";
import { PolotnoContainer, SidePanelWrap, WorkspaceWrap } from "polotno";
import { Toolbar } from "polotno/toolbar/toolbar";
import { ZoomButtons } from "polotno/toolbar/zoom-buttons";
import { SidePanel } from "polotno/side-panel";
import { Workspace } from "polotno/canvas/workspace";
import { QrSection } from "polotno-editor/components/customQrTab/CustomQrTab";
import { DEFAULT_SECTIONS } from "polotno/side-panel";
import Savebutton from "./polotno-editor/components/saveButton/Savebutton";
import { CustomTemplateTab } from "polotno-editor/components/customTemplateTab/CustomTemplateTab";
import { CustomResizePanel } from "polotno-editor/components/customResizePanel/CustomResizePanel";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import Filter from "bad-words";
import naughtyWords from "naughty-words";

// axios and end points
import axios from "api/axios";
import Endpoints from "api/Endpoints";

import {
  hidePopUpHandler,
  popUpImgHandler,
  setIsBad,
} from "store/slices/uiSlice";
import {
  addId,
  addTemplates,
  updateTemplates,
} from "store/slices/templateSlice";
import { showLoadingAlert } from "utils/showLoadingAlert";
import { useEffect, useState } from "react";
import { urltoFile } from "utils/urltoFile";

function App({ polotnoStore }) {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const templateParamId = urlParams.get("templateId");
  // redux states
  const showPopUp = useSelector((state) => state.ui.showPopUp);
  const isBad = useSelector((state) => state.ui.isBad);
  const popUpImg = useSelector((state) => state.ui.popUpImg);
  const templatesId = useSelector((state) => state.templates.templateId);
  let mediaIds = [];
  let apiStatus = [];
  let templateId = null;
  const dispatch = useDispatch();

  // Get single Template on Edit
  const getsingleTemplate = async () => {
    polotnoStore.openSidePanel("custom-templates");
    try {
      const res = await axios.get(`${Endpoints.template}/${templateParamId}`);
      const fetchedData = res?.data?.result?.template?.settings;
      // console.log(fetchedData, "res for single template");
      if (res.status === 200) {
        await polotnoStore.loadJSON(await JSON.parse(fetchedData));
      }
    } catch (error) {
      Swal.fire(
        "Error!",
        "Something went wrong! Please try closing the editor and come back again",
        "error"
      );
    }
  };
  useEffect(() => {
    if (templateParamId) {
      const idtemplate = JSON.parse(templateParamId);
      dispatch(addId(idtemplate));
      getsingleTemplate();
    }
  }, []);

  const getFactor = (origalVal, targetedVal) => {
    const originalWidth = origalVal; // Original width in pixels
    const targetWidth = targetedVal; // Target width in pixels
    if (origalVal === targetedVal) {
      return 1;
    }
    const scaleFactor = targetWidth / originalWidth;
    // console.log(scaleFactor,  "scaleFactor")
    // const newMeasurement = originalWidth * scaleFactor;
    return scaleFactor;
  };

  const ResizeSection = DEFAULT_SECTIONS.find(
    (section) => section.name === "size"
  );
  // overwrite its panel component
  ResizeSection.Panel = CustomResizePanel;
  const sections = [QrSection, CustomTemplateTab, ...DEFAULT_SECTIONS];
  const filteredSections = sections.filter(
    (section) => section.name !== "templates"
  );

  const emitEventToParent = () => {
    const message = {
      type: "sendMediaIdsToParent",
      data: JSON.stringify({
        mediaIds,
        templateId: templateParamId
          ? templateParamId
          : templatesId
          ? templatesId
          : templateId,
      }),
    };
    window.parent.postMessage(message, "*"); // '*' can be replaced with the parent window's origin for added security
    // console.log('event function  hit ',mediaIds)
  };

  const localStorageReset = () => {
    localStorage.setItem("cancelState", true);
    localStorage.setItem("apiCounter", 0);
    localStorage.setItem("savePressed", false);
    localStorage.setItem("updatePressed", false);
    localStorage.setItem("temId", undefined);
    localStorage.setItem("templateStatus", undefined);
    localStorage.setItem("tempJson", undefined);
  };
  const showLoadedAlert = (message) => {
    Swal.fire({
      title: message,
      text: `Your Ad has been ${
        message === "Saved" ? "saved" : "updated"
      } successfully.`,
      icon: "success",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorageReset();
        emitEventToParent();
        if (message === "Saved") {
          polotnoStore.clear();
          polotnoStore.addPage();
          polotnoStore.openSidePanel("photos");
        }
      }
    });
  };

  const mediaGenrator = async (id, type) => {
    let apiFailed = false;
    const sizes = [
      { width: 1600, height: 720 }, // mobile
      { width: 1280, height: 720 }, // tv
      { width: 1280, height: 800 }, // tab
    ];
    const keys = ["Mobile", "TV", "Tab"];
    async function processSizeAndAppendToPayload(width, height, key) {
      const payload = new FormData();
      const data = new Date();
      await polotnoStore.waitLoading();
      polotnoStore.activePage.set({ bleed: 20 }); // set bleed in pixels
      polotnoStore.toggleBleed(true);
      const originalWidth = await polotnoStore.width;
      const originalHeight = await polotnoStore.height;
      const widthFactor = getFactor(originalWidth, width);
      const heightFactor = getFactor(originalHeight, height);
      await polotnoStore.setSize(width, height);
      if (widthFactor) {
        await polotnoStore.activePage?.children.forEach((element) => {
          const name = element.name;
          const type = element.type;
          const text = element?.text;
          // console.log(text, "ELEMENT PROPS");
          const fontFamily = element.fontFamily;
          const fontWeight = element.fontWeight;
          if (type === "text") {
            element.set({
              fontSize:
                (key === "Mobile" && element.fontSize * heightFactor) ||
                element.fontSize * widthFactor,

              width:
                (key === "Mobile" && element.width * widthFactor) ||
                (key === "TV" && element.width * widthFactor) ||
                (key === "Tab" && element.width * widthFactor),
              height: element.height * heightFactor,
              x: element.x * widthFactor,
              y: element.y * heightFactor,
              keepRatio: true,
              resizeable: true,
            });
          } else if (name === "qr") {
            element.set({
              width: element.width * heightFactor,
              height: element.height * heightFactor,
              x: element.x * widthFactor,
              y: element.y * heightFactor,
              keepRatio: true,
              resizeable: true,
            });
          } else if (type === "svg") {
            element.set({
              width: element.width * widthFactor,
              height: element.height * widthFactor,
              x: element.x * widthFactor,
              y: element.y * heightFactor,
              keepRatio: true,
              resizeable: true,
            });
          } else if (type === "image") {
            element.set({
              width: element.width * widthFactor,
              height: element.height * heightFactor,
              x: element.x * widthFactor,
              y: element.y * heightFactor,
              keepRatio: true,
              resizeable: true,
            });
          } else {
            element.set({
              width: element.width * widthFactor,
              height: element.height * heightFactor,
              x: element.x * widthFactor,
              y: element.y * heightFactor,
              keepRatio: true,
              resizeable: true,
            });
          }
        });
        const url = await polotnoStore.toDataURL({ mimeType: "image/jpg" });
        const file = await urltoFile(
          url,
          data.getTime() + ".jpg",
          "image/jpeg"
        );
        // console.log(file, "----------file");
        payload.append("media[]", file);
        payload.append("user_id", "1");
        payload.append("other", "Ads");
        payload.append("device_type", key);

        if (type === "send") {
          payload.append("template_id", id);
          const status = await sendImgAPI(payload, key, id);
          if (status) {
            // polotnoStore.history.undo();
          }
          if (status !== 200) {
            apiFailed = true;
          }
          // console.log("Post Media APi Hit");
        } else {
          payload.append("template_id", templatesId);
          const status = await updateImgApi(payload, key);
          if (status) {
            // polotnoStore.history.undo();
          }
          if (status !== 200) {
            apiFailed = true;
          }
          // console.log("Update Media APi Hit");
        }
      }
    }
    (async () => {
      for (let i = 0; i < sizes.length; i++) {
        if (apiFailed) {
          break;
        }
        await processSizeAndAppendToPayload(
          sizes[i].width,
          sizes[i].height,
          keys[i]
        );
      }
    })();
  };
  const sendTemplateMedia = async (width, height, key, id, type) => {
    let counter = parseInt(localStorage.getItem("apiCounter"));
    const payload = new FormData();
    const data = new Date();
    await polotnoStore.waitLoading();
    polotnoStore.activePage.set({ bleed: 20 }); // set bleed in pixels
    polotnoStore.toggleBleed(true);
    const originalWidth = await polotnoStore.width;
    const originalHeight = await polotnoStore.height;
    const widthFactor = getFactor(originalWidth, width);
    const heightFactor = getFactor(originalHeight, height);
    await polotnoStore.setSize(width, height);
    if (widthFactor) {
      // console.log("widthFactor", widthFactor);
      await polotnoStore.activePage?.children.forEach((element) => {
        const name = element.name;
        const type = element.type;
        const text = element?.text;
        // console.log(text, "ELEMENT PROPS");
        const fontFamily = element.fontFamily;
        const fontWeight = element.fontWeight;
        if (type === "text") {
          element.set({
            fontSize:
              (key === "Mobile" && element.fontSize * heightFactor) ||
              element.fontSize * widthFactor,

            width:
              (key === "Mobile" && element.width * widthFactor) ||
              (key === "TV" && element.width * widthFactor) ||
              (key === "Tab" && element.width * widthFactor),
            height: element.height * heightFactor,
            x: element.x * widthFactor,
            y: element.y * heightFactor,
            // keepRatio: true,
            resizeable: true,
          });
        } else if (name === "qr") {
          element.set({
            width: element.width * heightFactor,
            height: element.height * heightFactor,
            x: element.x * widthFactor,
            y: element.y * heightFactor,
            // keepRatio: true,
            resizeable: true,
          });
        } else if (type === "svg") {
          element.set({
            width: element.width * widthFactor,
            height: element.height * widthFactor,
            x: element.x * widthFactor,
            y: element.y * heightFactor,
            // keepRatio: true,
            resizeable: true,
          });
        } else if (type === "image") {
          element.set({
            width: element.width * widthFactor,
            height: element.height * heightFactor,
            x: element.x * widthFactor,
            y: element.y * heightFactor,
            // keepRatio: true,
            resizeable: true,
          });
        } else {
          element.set({
            width: element.width * widthFactor,
            height: element.height * heightFactor,
            x: element.x * widthFactor,
            y: element.y * heightFactor,
            // keepRatio: true,
            resizeable: true,
          });
        }
      });
      const url = await polotnoStore.toDataURL({ mimeType: "image/jpg" });
      dispatch(popUpImgHandler(url));
      const file = await urltoFile(url, data.getTime() + ".jpg", "image/jpeg");
      // console.log(file, "----------file");
      payload.append("media[]", file);
      payload.append("user_id", "1");
      payload.append("other", "Ads");
      payload.append("device_type", key);
      payload.append("template_id", id);
      if (payload) {
        // console.log("payload", payload, sendTemplateMedia);
        Swal.fire({
          title: `${key} Media Preview`,
          showCancelButton: true,
          confirmButtonText: "Save",
          cancelButtonText: "Edit",
          imageUrl: url,
          imageAlt: "Custom image",
        }).then(async (result) => {
          if (result.isConfirmed) {
            if (type === "update") {
              const status = await updateImgApi(payload, key);
              if (status) {
                // polotnoStore.history.undo();
                // counter++;
                localStorage.setItem("apiCounter", counter + 1);
                updateMedia(templatesId, type);
                return status;
              }
            } else {
              // console.log("result.isConfirmed", result.isConfirmed);
              const status = await sendImgAPI(payload, key, id);
              if (status === 200) {
                // polotnoStore.history.undo();
                // counter++;
                localStorage.setItem("apiCounter", counter + 1);
                saveMedia(id, type);
                return status;
              }
            }
          } else if (result.isDismissed) {
            // alert('i am hit')
            localStorage.setItem("cancelState", false);
          }
        });
      }
    }
  };

  // ====================== POST API ============================
  // Json Post
  const sendTemplateAPI = async () => {
    const counter = localStorage.getItem("apiCounter");
    const message = "Saving";
    showLoadingAlert(message);
    // console.log("counter in template", counter);
    // console.log("save json clicked");
    const templateJson = JSON.stringify(await polotnoStore.toJSON());
    // console.log(templateJson, "Json of Template ");
    const data = {
      // user_id: "1",
      settings: templateJson,
    };
    try {
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
      };
      const res = await axios.post(Endpoints.template, data, {
        headers: headers,
      });
      // console.log("TEMPLATE API RESPONSE --------", res);
      templateId = res?.data?.result?.template?.id;
      if (res.status === 200) {
        await polotnoStore.history.clear();
        const json = await JSON.stringify(await polotnoStore.toJSON());
        const prev = await polotnoStore.toDataURL({ mimeType: "image/jpg" });
        const id = res?.data?.result?.template?.id;
        dispatch(
          addTemplates({
            json,
            prev,
            id,
          })
        );
        localStorage.setItem("tempJson", json);
      }
      return res?.data?.result?.template?.id;
    } catch (error) {
      // console.log(error.response.data,'template error on post')
      Swal.fire(
        "Error!",
        "Something went wrong! Please try closing the editor and come back again",
        "error"
      ).then((result) => {
        if (result.isConfirmed) {
          localStorageReset();
        } else if (result.isDenied) {
          localStorageReset();
        }
      });
    }
  };
  // Media Post
  const sendImgAPI = async (payload, key, id) => {
    const cancelStatus = localStorage.getItem("cancelState");
    const tempJson = localStorage.getItem("tempJson");
    // console.log(tempJson, "tempjson");
    const message = "Saving";
    showLoadingAlert(message);
    const headers = {
      Accept: "application/json",
    };
    try {
      const res = await axios.post(Endpoints.sendMedia, payload, {
        headers: headers,
      });
      mediaIds.push(res?.data?.result?.media[0]?.id);
      // console.log(`MEDIA-${key}`, res);
      // console.log(mediaIds, "======================");
      // if (cancelStatus == "false" && key !== "Tab" && res.status === 200) {
      //   await polotnoStore.history.undo();
      // }
      if (res.status === 200 && tempJson) {
        await polotnoStore.loadJSON(await JSON.parse(tempJson));
      }
      if (key === "Tab" && res.status === 200) {
        if (id) {
          // await polotnoStore.history.undo();
          // const json = await JSON.stringify(await polotnoStore.toJSON());
          // const prev = await polotnoStore.toDataURL({ mimeType: "image/jpg" });
          // dispatch(
          //   addTemplates({
          //     json,
          //     prev,
          //     id,
          //   })
          // );
          const message = "Saved";
          showLoadedAlert(message);
          // emitEventToParent();
        }
      }
      return res.status;
    } catch (error) {
      const status = error.response.status;
      if (status === 401) {
        Swal.fire(
          "Error!",
          "Something went wrong! Please try closing the editor and come back again",
          "error"
        ).then((result) => {
          if (result.isConfirmed) {
            localStorageReset();
          } else if (result.isDenied) {
            localStorageReset();
          }
        });
      } else {
        Swal.fire("Error!", `${key} Media saving failed`, "error").then(
          (result) => {
            if (result.isConfirmed) {
              localStorageReset();
            } else if (result.isDenied) {
              localStorageReset();
            }
          }
        );
      }
      return status;
    }
  };

  //========================== Update API========================

  // JSON UPDATE
  const updateJsonAPI = async (updateData) => {
    const message = "Updating";
    showLoadingAlert(message);
    // console.log("update json clicked");
    try {
      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
      };
      const res = await axios.post(
        `${Endpoints.template}/${templatesId}`,
        updateData,
        {
          headers: headers,
        }
      );
      if (res.status === 200) {
        await polotnoStore.history.clear();

        if (templatesId) {
          const json = await JSON.stringify(await polotnoStore.toJSON());
          const prev = await polotnoStore.toDataURL({ mimeType: "image/jpg" });
          const storeData = {
            id: templatesId,
            json: json,
            prev: prev,
          };
          dispatch(updateTemplates(storeData));
          localStorage.setItem("tempJson", json);
        }
      }
      return res.status;
    } catch (error) {
      Swal.fire(
        "Error!",
        "Something went wrong! Please try closing the editor and come back again",
        "error"
      ).then((result) => {
        if (result.isConfirmed) {
          localStorageReset();
        } else if (result.isDenied) {
          localStorageReset();
        }
      });
    }
  };
  // IMAGE UPDATE
  const updateImgApi = async (payload, key) => {
    const cancelStatus = localStorage.getItem("cancelState");
    const tempJson = localStorage.getItem("tempJson");
    const message = "Updating";
    showLoadingAlert(message);
    // for (let it of payload) {
    //   console.log(it, "update media api");
    // }
    const headers = {
      Accept: "application/json",
    };
    try {
      const res = await axios.post(`${Endpoints.updateMedia}`, payload, {
        headers: headers,
      });
      // console.log(`MEDIA-${key}`, res);
      mediaIds.push(res?.data?.result?.media?.id);
      // if (cancelStatus == "false" && key !== "Tab" && res.status === 200) {
      //   await polotnoStore.history.undo();
      // }
      if (res.status === 200 && tempJson) {
        await polotnoStore.loadJSON(await JSON.parse(tempJson));
      }
      if (key === "Tab" && res.status === 200) {
        if (templatesId) {
          // polotnoStore.history.undo();
          // const json = await JSON.stringify(await polotnoStore.toJSON());
          // const prev = await polotnoStore.toDataURL({ mimeType: "image/jpg" });
          // const storeData = {
          //   id: templatesId,
          //   json: json,
          //   prev: prev,
          // };
          // dispatch(updateTemplates(storeData));
        }
        const message = "Updated";
        showLoadedAlert(message);
      }
      return res.status;
    } catch (error) {
      const status = error.response.status;
      if (status === 401) {
        Swal.fire(
          "Error!",
          "Something went wrong! Please try closing the editor and come back again",
          "error"
        ).then((result) => {
          if (result.isConfirmed) {
            localStorageReset();
          } else if (result.isDenied) {
            localStorageReset();
          }
        });
      } else {
        Swal.fire("Error!", `${key} Media updating failed`, "error").then(
          (result) => {
            if (result.isConfirmed) {
              localStorageReset();
            } else if (result.isDenied) {
              localStorageReset();
            }
          }
        );
      }
      return status;
    }
  };

  let tempStatus = null;
  const handleUpdateClick = () => {
    const cancelStatus = localStorage.getItem("cancelState");
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Update it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        localStorage.setItem("updatePressed", true);
        const json = await JSON.stringify(await polotnoStore.toJSON());
        const updateData = {
          settings: json,
        };
        if (cancelStatus == "true") {
          tempStatus = await updateJsonAPI(updateData);
          localStorage.setItem("templateStatus", tempStatus);
        }
        const status = localStorage.getItem("templateStatus");
        if (tempStatus === 200 || status == "200") {
          const type = "update";
          // await mediaGenrator(type);
          updateMedia(templatesId, type);
        }
      }
    });
  };
  let temId = null;
  // Export Button for Save , Update , Add QR
  const element = polotnoStore?.getElementById("q");
  if (showPopUp) {
    const savePressed = localStorage.getItem("savePressed");
    const updatePressed = localStorage.getItem("updatePressed");
    localStorage.getItem("updatePressed");
    const cancelStatus = localStorage.getItem("cancelState");
    Swal.fire({
      title: "Do you want to save the changes?",
      html: `${
        !element
          ? `<button id="btn1" type="button" class="btn btn-custom popup_qr_button">Add QR Code</button>`
          : ""
      }`,
      showConfirmButton: updatePressed == "false" && !templateParamId && true,
      showDenyButton: savePressed == "false" && templatesId && true,
      showCancelButton: true,
      confirmButtonText: `${
        savePressed === "true" ? "Update Current" : "Save as New"
      }`,
      denyButtonText: `Update Changes`,
      cancelButtonText: `Cancel`,
      showCloseButton: true,
      imageUrl: popUpImg,
      imageWidth: 400,
      imageHeight: 200,
      imageAlt: "Custom image",
      didOpen: () => {
        const btn1 = document?.getElementById("btn1");
        btn1?.addEventListener("click", () => {
          // console.log("Button 1 Clicked");
          Swal.close();
          polotnoStore.openSidePanel("qr");
          // Call your custom function for Button 1 here
        });
      },

      customClass: {
        confirmButton: "save_button_popUP",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        localStorage.setItem("savePressed", true);
        if (cancelStatus == "true") {
          temId = await sendTemplateAPI();
          localStorage.setItem("temId", temId);
        }
        const id = localStorage.getItem("temId");
        if (id !== "undefined" || temId) {
          // const type = "send";
          // await mediaGenrator(id, type);
          saveMedia(id || temId);
        }
      } else if (result.isDenied) {
        handleUpdateClick();
      } else if (result.isDismissed) {
        dispatch(hidePopUpHandler());
      }
      dispatch(hidePopUpHandler());
    });
  }
  const saveMedia = async (id, type) => {
    const counter = localStorage.getItem("apiCounter");
    if (counter == "0") {
      const width = 1600;
      const height = 720;
      const key = "Mobile";
      const status = await sendTemplateMedia(width, height, key, id, type);
      // console.log("status inside function", status);
    }
    if (counter == "1") {
      const width = 1280;
      const height = 720;
      const key = "TV";
      const status = await sendTemplateMedia(width, height, key, id, type);
    }
    if (counter == "2") {
      const width = 1280;
      const height = 800;
      const key = "Tab";
      const status = await sendTemplateMedia(width, height, key, id, type);
    }
  };
  const updateMedia = async (templatesId, type) => {
    const counter = localStorage.getItem("apiCounter");
    if (counter == "0") {
      const width = 1600;
      const height = 720;
      const key = "Mobile";
      const status = await sendTemplateMedia(
        width,
        height,
        key,
        templatesId,
        type
      );
      // console.log("status inside function", status);
    }
    if (counter == "1") {
      const width = 1280;
      const height = 720;
      const key = "TV";
      const status = await sendTemplateMedia(
        width,
        height,
        key,
        templatesId,
        type
      );
    }
    if (counter == "2") {
      const width = 1280;
      const height = 800;
      const key = "Tab";
      const status = await sendTemplateMedia(
        width,
        height,
        key,
        templatesId,
        type
      );
    }
  };
  // PROFANITY
  const badWords = naughtyWords?.en;
  const profanityFilter = new Filter({ list: [...badWords] });
  let debounceTimer;
  const profanityCheck = () => {
    polotnoStore.activePage?.children.forEach((element) => {
      const type = element?.type;
      const text = element?.text;
      if (type === "text") {
        // Check if the text contains profanity
        if (profanityFilter.isProfane(text)) {
          // Replace profanity with asterisks
          element.set({ text: profanityFilter.clean(text) });
          Swal.fire("Bad word detected, Remove it!");
        }
      }
      // console.log('type', type);
      // console.log("text", text);
    });
  };
  const delayedProfanityCheck = () => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      profanityCheck();
    }, 1000); // Adjust the delay time (in milliseconds) as needed
  };
  polotnoStore.on("change", () => {
    delayedProfanityCheck();
    const sidePanel = polotnoStore.openedSidePanel;
    if (sidePanel === "templates") {
      dispatch(addId(null));
    }
  });
  return (
    <>
      <PolotnoContainer
        style={{ width: "100vw", height: "100vh" }}
        className="polotno-app-container bp5-dark"
      >
        <SidePanelWrap>
          <SidePanel store={polotnoStore} sections={filteredSections} />
        </SidePanelWrap>
        <WorkspaceWrap>
          <div className="water_mark_styles" />
          <Toolbar
            store={polotnoStore}
            components={{ ActionControls: Savebutton }}
            hideImageRemoveBackground
            hideImageFit
          />
          <Workspace store={polotnoStore} />
          <ZoomButtons store={polotnoStore} />
        </WorkspaceWrap>
      </PolotnoContainer>
    </>
  );
}
export default App;
