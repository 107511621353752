import { createSlice } from "@reduxjs/toolkit";

export const uiSlice = createSlice({
  name: "ui",
  initialState: {
    addQr: false,
    showPopUp: false,
    popUpImg:null,
    isBad:false
  },
  reducers: {
    qrHandler: (state) => {
      state.addQr = true;
    },
    showPopUpHandler: (state) => {
      state.showPopUp = true;
    },
    hidePopUpHandler: (state) => {
      state.showPopUp = false;
    },
    popUpImgHandler:(state,action)=>{
      state.popUpImg=action.payload;
    },
    setIsBad:(state,action)=>{
      state.isBad=action.payload;
    }
  },
});

export const { qrHandler, showPopUpHandler, hidePopUpHandler,popUpImgHandler,setIsBad } =
  uiSlice.actions;
export default uiSlice.reducer;
